export const cards=[
{cls:"card_car",title:"Скидка<br />на Атом EV",price:"9 900",count:"количество ограничено",coming:false,disabled:false,bottomtitle:"Электромобиль-гаджет с широкими возможностями персонализации и цифрового взаимодействия с городом",bg:"car.png",top:1,real:false,bigtext:"<a href='https://atom.auto' target='_blank'>Атом</a> — это больше, чем электромобиль. Это умный гаджет, который сочетает стильный дизайн, передовые технологии и возможности полной персонализации. Важно: чем ближе дата выхода, тем меньше будет доступная скидка. Не упустите возможность стать частью будущего уже сегодня! <br><br><a href='https://store.protone.app/Rules_Atom.pdf' target='_blank'>Правила проведения акции</a>",tip:"Особые"
,slider:[
{'file': '/i/store/atom/7.jpg','preview': '/i/store/atom/7p.jpg'},
{'file': '/i/store/atom/8.jpg','preview': '/i/store/atom/8p.jpg'},
{'file': '/i/store/atom/9.jpg','preview': '/i/store/atom/9p.jpg'},
{'file': '/i/store/atom/10.jpg','preview': '/i/store/atom/10p.jpg'},
{'file': '/i/store/atom/11.jpg','preview': '/i/store/atom/11p.jpg'},
{'file': '/i/store/atom/12.jpg','preview': '/i/store/atom/12p.jpg'},
{'file': '/i/store/atom/17.jpg','preview': '/i/store/atom/17p.jpg'},
{'file': '/i/store/atom/18.jpg','preview': '/i/store/atom/18p.jpg'},
{'file': '/i/store/atom/23.jpg','preview': '/i/store/atom/23p.jpg'},
{'file': '/i/store/atom/0.jpg','preview': '/i/store/atom/0p.jpg'},
{'file': '/i/store/atom/1.jpg','preview': '/i/store/atom/1p.jpg'},
{'file': '/i/store/atom/2.jpg','preview': '/i/store/atom/2p.jpg'},
{'file': '/i/store/atom/3.jpg','preview': '/i/store/atom/3p.jpg'},
{'file': '/i/store/atom/4.jpg','preview': '/i/store/atom/4p.jpg'},
{'file': '/i/store/atom/5.jpg','preview': '/i/store/atom/5p.jpg'},
{'file': '/i/store/atom/6.jpg','preview': '/i/store/atom/6p.jpg'},
{'file': '/i/store/atom/13.jpg','preview': '/i/store/atom/13p.jpg'},
{'file': '/i/store/atom/14.jpg','preview': '/i/store/atom/14p.jpg'},
{'file': '/i/store/atom/15.jpg','preview': '/i/store/atom/15p.jpg'},
{'file': '/i/store/atom/16.jpg','preview': '/i/store/atom/16p.jpg'},
{'file': '/i/store/atom/19.jpg','preview': '/i/store/atom/19p.jpg'},
{'file': '/i/store/atom/20.jpg','preview': '/i/store/atom/20p.jpg'},
{'file': '/i/store/atom/21.jpg','preview': '/i/store/atom/21p.jpg'},
{'file': '/i/store/atom/22.jpg','preview': '/i/store/atom/22p.jpg'},
{'file': '/i/store/atom/24.jpg','preview': '/i/store/atom/24p.jpg'},
{'file': '/i/store/atom/25.jpg','preview': '/i/store/atom/25p.jpg'},
{'file': '/i/store/atom/26.jpg','preview': '/i/store/atom/26p.jpg'},
{'file': 'https://vk.com/video_ext.php?oid=-216090997&id=456239354&hd=2&autoplay=1','preview': '/i/store/atom/12p.jpg'},
]	
},
{cls:"card_dark",title:"Эйрподсы",price:"42 000",count:"2",disabled:false,bottomtitle:"Минимум шума,<br />максимум стиля",bg:"airpods.png",top:4,real:true,delivery:0,pickup:true,bigtext:"Знаменитые беспроводные наушники, ставшие классикой. Легкие, стильные и функциональные. Они идеально подходят для активного образа жизни и обеспечивают высокое качество звука. Получить оригинальные AirPods бесплатно можно обменяв свои накопленные в приложении протоны.",tip:"Гаджеты"},
{cls:"card_darkgreen",title:"Эко-стеллаж<br />для зелени",price:"15 000",count:"1",disabled:false,bottomtitle:"Собственная сити-ферма для выращивания зелени и овощей. Просто добавь воды!",bg:"stel.png",top:2,real:true,delivery:0,pickup:true,bigtext:"Футуристичный и функциональный стеллаж фитолампами для рассады и микрозелени с фитолампами “sunlike” спектра. Удобная конструкция позволяет собирать и разбирать стеллаж всего за 10-15 минут. <br /><br />Стеллаж изготовлен из перерабатываемого ABS пластика, каждая полка выдерживает до 70 кг, а блоки питания надежно защищены от влаги. Три съемных фитосветильника мощностью по 20W обеспечивают яркое и равномерное освещение. Создайте свою экоферму прямо у себя дома и наслаждайтесь цветущим садом круглый год!",tip:"Особые"},
{cls:"card_bilet",title:"Билет<br />на выставку ",price:"950",count:"11",disabled:false,bottomtitle:"Выставка Музея Транспорта Москвы об истории транспортного дизайна в России за последние 100 лет",bg:"bilet.png",top:2,real:false,delivery:false,pickup:false,bigtext:"Билет на выставку <a href='https://vdnh.ru/events/vystavka-ty-vyglyadish-na-vse-100-/'  target='_blank' rel='noreferrer'>“Ты выглядишь на все сто!”</a> от Музея Транспорта Москвы, которая проходит в павильоне «Транспорт СССР» на ВДНХ с 7 ноября 2024 года до 1 октября 2025 года. На выставке представлены как легендарные машины и архивные материалы, так и современные разработки, такие как электромобиль “Атом”, воплощающий новые идеи и технологии. ",tip:"Особые"
,slider:[
{"file": "/i/store/bilet/2.jpg","preview": "/i/store/bilet/2p.jpg"},
{"file": "/i/store/bilet/1.jpg","preview": "/i/store/bilet/1p.jpg"},
{"file": "/i/store/bilet/0.jpg","preview": "/i/store/bilet/0p.jpg"},
{"file": "/i/store/bilet/3.jpg","preview": "/i/store/bilet/3p.jpg"},
{"file": "/i/store/bilet/4.jpg","preview": "/i/store/bilet/4p.jpg"},
{"file": "/i/store/bilet/5.jpg","preview": "/i/store/bilet/5p.jpg"},
{"file": "/i/store/bilet/6.jpg","preview": "/i/store/bilet/6p.jpg"},
	],	
},
{cls:"card_dinamo",title:"Билет в Музей<br/>Динамо.",price:"200",count:"10",disabled:false,bottomtitle:"История тех, кто изменил представления о спорте и поднял нашу страну на первые места пъедесталов",bg:"dinamo.png",top:1,real:false,bigtext:"Обменяй протоны на билет в Музей Динамо.<br />Билет дает право на бесплатное посещение музея.<br />Срок действия до 31.03.2025.",tip:"Особые"},
{cls:"card_purple",title:"Яндекс. Станция Лайт",price:"12 000",count:"1",disabled:true,bottomtitle:"Крохотная, но функциональная —умная колонка с голосовым управлением, которая сделает твою жизнь проще",bg:"yandex_mini.png",top:2,real:true,delivery:0,pickup:true,bigtext:"Миниатюрная, но функциональная — умная колонка с голосовым управлением, которая сделает твою жизнь проще.",tip:"Гаджеты"},
{cls:"card_orange",title:"Xiaomi Smart Band 8 Active",price:"8 000",count:"3",disabled:true,bottomtitle:"Твой личный фитнес-тренер на запястье! Мониторит пульс, шаги и напоминает, когда пора двигаться",bg:"xioami_band.png",top:2,real:true,delivery:0,pickup:true,bigtext:"Твой личный фитнес-тренер на запястье! Мониторит пульс, шаги и напоминает, когда пора двигаться. ",tip:"Гаджеты"},
{cls:"card_noski",title:"Прикольные<br />носки",price:"1 400",count:"7",bottomtitle:"Удобные, яркие, с крутым дизайном — для тех, кто любит выделяться",bg:"noski.png",top:1,big:false,real:true,delivery:0,pickup:true,tip:"Мерч"
,	opt:[ //1300
{name:'color',title:'Фасон',selected:2,tip:"color",arr:[
{value:"белые с грибами",status:false, bg: "/i/store/noski/grib.jpg",slider:0,disabled:[{name:'size',disabled:[0]}]},
{value:"белые с гусями",status:false, bg: "/i/store/noski/gusi.jpg",slider:1,disabled:[{name:'size',disabled:[0, 1]}]},
{value:"желтые с капибарой",status:true, bg: "/i/store/noski/kapibara.jpg",slider:2,disabled:[{name:'size',disabled:[0]}]},
{value:"зеленые",status:true, bg: "/i/store/noski/green.jpg",slider:3,disabled:[{name:'size',disabled:[]}]},
]
},
{name:'size',title:'Размер',selected:0,tip:"text",arr:[
{value:"M",status:true},
{value:"L",status:true},
]
},
		],slider:[
{"file": "/i/store/noski/1.jpg","preview": "/i/store/noski/1p.jpg"},
{"file": "/i/store/noski/2.jpg","preview": "/i/store/noski/2p.jpg"},
{"file": "/i/store/noski/3.jpg","preview": "/i/store/noski/3p.jpg"},
{"file": "/i/store/noski/4.jpg","preview": "/i/store/noski/4p.jpg"},
	],
bigtext:"Носи, чтобы выделяться и ловить восхищенные взгляды! Мягкие и прочные, эти носки из натурального хлопка с добавлением синтетики легко выдержат любые испытания и добавят яркий акцент твоему образу."
},
{cls:"card_blue",title:"Футболка <br />тай-дай",price:"4 100",count:"1",disabled:true,bottomtitle:"Уникальная тай-дай футболка из хлопка, которая будет только у тебя! Каждый принт неповторим!",bg:"t_shirt.png",top:1,real:true,delivery:0,pickup:true,bigtext:"Уникальная тайдай-футболка из хлопка, которая будет только у тебя. Каждый принт неповторим! ",tip:"Мерч"},
{cls:"card_pink",title:"Яндекс <br />Плюс",price:"2 250",count:"24",disabled:true,bottomtitle:"3 месяца<br />Музыка, фильмы,<br/>кешбэк баллами",bg:"yandex.png",top:1,real:false,bigtext:"Подписка на 3 месяца - музыка, фильмы, а также кэшбек баллами Яндекса и другие плюшки.",tip:"Подписки"},
{cls:"card_aqua",title:"Телеграм<br/>Премиум",price:"3 000",disabled:true,count:"23",bottomtitle:"Подписка на 3 месяца - отсутствие рекламы, расшифровка войсов, больше лимиты, и многое другое",bg:"telegram.png",top:1,real:false,bigtext:"Подписка на 3 месяца - отсутствие рекламы, расшифровка войсов, больше лимиты, и многое другое.",tip:"Подписки"},
{cls:"card_blue",title:"Офигенное<br />худи",price:"9 000",count:"8",bottomtitle:"Лимитный мерч от наших дизайнеров",bg:"hudi.png",top:1,big:false,real:true,delivery:0,pickup:true,tip:"Мерч"
,	opt:[
{name:'color',title:'Цвет',selected:0,tip:"color",arr:[
{value:"#000",status:true},/*
{value:"#fff",status:true},
{value:"#c4c4c4",status:true},
{value:"#ff1414",status:true},
{value:"#117eff",status:true},
{value:"#60fd00",status:true},*/
]
},
{name:'size',title:'Размер',selected:2,tip:"text",arr:[
{value:"XS",status:false},
{value:"S",status:false},
{value:"M",status:true},
{value:"L",status:true},
{value:"XL",status:true},
{value:"XXL",status:false},
]
},
		],slider:[
{"file": "/i/store/hudi/1.jpg","preview": "/i/store/hudi/1p.jpg"},
	],
bigtext:"Наше лимитированное худи с вышивкой – идеальное сочетание стиля и комфорта. Дизайн этого дропа разработан нашими дизайнерами ограниченной партией.<br /><br />Оверсайз крой и мягкий капюшон обеспечивают максимальный комфорт, позволяют свободно двигаться и чувствовать себя уютно в любых условиях. В кенгуру-кармане спереди можно хранить мелочи или согреть руки в непогоду. <br /><br />Худи изготовлено из 80% хлопка и 20% полиэстера, что гарантирует долговечность, мягкость и легкость в уходе, а вышитый рисунок никогда не сотрется."
},
{cls:"card_troika",title:"Карта Тройка<br />“Куплю Гараж”",price:"1 350",count:"2",disabled:true,bottomtitle:"Лимитированное издание по мотивам одноимённой выставки Музея Транспорта Москвы «Куплю гараж»",bg:"troika1.png",top:2,real:true,delivery:0,pickup:true,bigtext:"Лимитированная карта Тройка к выставке «Куплю Гараж» от Музея Транспорта Москвы, посвященной мечте миллионов советских людей — гаражу, а также гаражной культуре.",tip:"Особые"
,slider:[
{"file": "/i/store/troika1/1.jpg","preview": "/i/store/troika1/1p.jpg"},
	],},
{cls:"card_troika",title:"Карта Тройка<br />“Москва-река”",price:"1 350",count:"1",disabled:true,bottomtitle:"Лимитированное издание по мотивам одноимённой книги Музея Транспорта Москвы «Москва-река пассажирская»",bg:"troika2.png",top:2,real:true,delivery:0,pickup:true,bigtext:"Коллекционное издание «Тройки», созданное в честь выставки «Москва-река пассажирская» от Музея Транспорта Москвы. Дизайнеры вдохновлялись атмосферой речных прогулок и историей московского водного транспорта.",tip:"Особые",slider:[
{"file": "/i/store/troika2/1.jpg","preview": "/i/store/troika2/1p.jpg"},
	],},
{cls:"card_blue2",title:"Многоразовая<br />бутылка",price:"2 600",count:"9",disabled:false,bottomtitle:"Сокращай использование пластика стильно",bg:"butilka.png",top:1,big:false,real:true,delivery:0,pickup:true,bigtext:"Эта стильная и экологичная бутылка изготовлена из высококачественной нержавеющей стали, которая не только долговечна, но и безопасна для окружающей среды – используя многоразовую емкость, вы снижаете количество пластиковых отходов. Нержавеющая сталь не впитывает запахи и не влияет на вкус напитков, а также безопасна для многократного использования. ",tip:"Мерч"},
{cls:"card_more_orange",title:"Купон Flowwow<br />на 500р.",price:"500",count:"10",disabled:false,bottomtitle:"Обменяй протоны на купон на 500 рублей и выбери что-то особенное для тех, кого любишь",bg:"cupon_flowwow.png",top:1,real:false,bigtext:"Обменяй протоны на купон на 500 рублей и выбери что-то особенное: цветы, игрушки, сладости и многое другое для тех, кого любишь!<br />Купон действует на любую покупку на маркетплейсе Flowwow от 3500 рублей.",tip:"Особые"},
{cls:"card_more_orange",title:"Промокод -10%<br />Flowwow",price:"300",count:"1000",disabled:false,bottomtitle:"Не забывай время от времени баловать себя и своих близких. А Flowwow поможет тебе в этом",bg:"promo_flowwow.png",top:1,real:false,bigtext:"Не забывай время от времени баловать себя и своих близких! А Flowwow поможет тебе в этом :)<br />Промокод на скидку 10% действует при покупке кондитерских изделий на маркетплейсе Flowwow.",tip:"Особые"},
{cls:"card_brown",title:"Протеиновое<br>печенье",price:"2 000",count:"22",disabled:true,bottomtitle:"Коробка из 12 печенек Bombbar Школадный брауни с высоким содержанием белка",bg:"cookie.png",top:1,real:true,delivery:0,pickup:false,bigtext:"Шоколадное печенье Bombbar — наслаждение и польза! Лакомство без сахара, но с насыщенным вкусом шоколада и высоким содержанием белка.",tip:"Особые"},
{cls:"card_brown",title:"Протеиновые батончики",price:"4 500",count:"10",disabled:true,bottomtitle:"Bombbar ассорти №4 — без сахара и лишних калорий, с высоким содержанием белка и клетчатки. 20 штук с 5 вкусами",bg:"baton.png",top:1,real:true,delivery:0,pickup:false,bigtext:"В этих батончиках нет сахара и и лишних калорий, зато много белка и клетчатки. Попробуй ассорти — в набор входят следующие вкусы: печенье с кремом (х4), венские вафли (х4), солёная карамель (х4), малиновый чизкейк (х4), лимонный торт (х4).",tip:"Особые"},

{cls:"card_yamaguchi",title:"Маска для лица<br>Yamaguchi",price:"1 050",count:"50",disabled:true,bottomtitle:"После этой маски твоя кожа будет выглядеть так, будто ты каждый день спишь по 8 часов",bg:"ya_maska.png",top:1,real:true,delivery:0,pickup:true,bigtext:"Yamaguchi Aqua Ring Mask — увлажняющая маска с гиалуроновой кислотой, центеллой азиатской, алоэ вера и портулаком для восстановления и защиты кожи. Нанеси на 15-20 минут и наслаждайся результатом.",tip:"Особые"},
{cls:"card_yamaguchi",title:"Массажный мяч<br>Yamaguchi",price:"1 550",count:"10",disabled:true,bottomtitle:"Этот массажный мячик крутится, как белка в колесе: и массаж сделает, и со стиркой поможет",bg:"ya_ball.png",top:1,real:true,delivery:0,pickup:true,bigtext:"Yamaguchi Acupoint — мячик для массажа всего тела: снимает напряжение в мышцах, улучшает кровообращение и помогает расслабиться после тяжелого дня. Лайфхак: его также можно использовать в стиральной машинке для защиты тканей от катышков и придания мягкости вещам.",tip:"Особые"},
{cls:"card_yamaguchi",title:"Скакалка<br>Yamaguchi",price:"3 150",count:"10",disabled:true,bottomtitle:"Быстрее, выше, сильнее. Кардио, которое ты полюбишь",bg:"ya_rope.png",top:1,real:true,delivery:0,pickup:true,bigtext:"Легкий и удобный аксессуар для домашних тренировок — вес скакалки всего 110 г. Регулируемая длина позволяет адаптировать ее под любой рост. Побочный эффект: обеспечит кардиотренировки, которые не только укрепят мышцы, но и улучшат состояние всего организма.",tip:"Особые"},
{cls:"card_yamaguchi",title:"Промокод на<br>Yamaguchi",price:"1 000",count:"100",disabled:true,bottomtitle:"Промокод на скидку 1000 рублей в магазине Yamaguchi: выбирай лучшие товары для фитнеса, здоровья и комфорта",bg:"ya_promo.png",top:1,real:false,bigtext:"Максимальный размер скидки 1000 рублей, но одновременно не более 30% от розничной стоимости.",tip:"Особые"},
{cls:"card_yamaguchi",title:"Рюкзак<br>Yamaguchi",price:"6 600",count:"10",disabled:true,bottomtitle:"Бросай в него кроссовки, форму и бутылку с водой — поместится все, что нужно для отличной тренировки",bg:"ya_bag.png",top:2,real:true,delivery:0,pickup:true,bigtext:"Универсальный рюкзак с основным отделением и внутренними карманами для мелочей. Сделан из прочных материалов — полиэстер и нейлон — и выдерживает до 6 кг. С ним можно прогуляться и ночью, ведь со светоотражающей молнией тебя точно заметят на дороге. А если рюкзак не нужен, он компактно складывается и занимает минимум места.",tip:"Особые"},
{cls:"card_litres",title:"Литрес<br>на 1 месяц",price:"900",count:"100",disabled:false,bottomtitle:"Получи доступ к огромной библиотеке книг — от классики до бестселлеров. Наслаждайся чтением, где бы ты ни был",bg:"litres_month.png",top:1,real:false,bigtext:"Обменяй протоны на месяц Литрес Подписки и читай и слушай более 800 000+ книг, аудиокниг и подкастов. Подборки на любой вкус — не трать время на поиск! Активировать промокод можно в разделе «Промокод» на сайте Литрес.<br>Срок активации: до 31.01.25",tip:"Подписки"},
{cls:"card_litres",title:"Литрес<br>на 2 месяца",price:"1 500",count:"100",disabled:false,bottomtitle:"Предложение для тех, кому и месяца подписки мало. Наслаждайтесь чтением в 2 раза дольше по более выгодной цене",bg:"litres_2month.png",top:1,real:false,bigtext:"Обменяй протоны на 2 месяца Литрес Подписки и читай и слушай более 800 000+ книг, аудиокниг и подкастов. Подборки на любой вкус — не трать время на поиск! Активировать промокод можно в разделе «Промокод» на сайте Литрес.<br>Срок активации: до 31.01.25",tip:"Подписки"},
]
export const cardsCategory=['Всё','Подписки','Мерч','Гаджеты','Особые'];
/*
{title:"Я могу подарить кому-то товары из магазина за свои Протоны?",
text:"Да, Вы можете приобрести приз из нашего магазина в качестве подарка для другого человека. В таком случае, сообщите об этом в графе «Комментарий»."},
{title:"Сгорают ли Протоны, если не использовать их? ",
text:"Нет, Протоны не сгорают. Вы можете сразу потратить их на минимальный приз, или же оставить валюту на счету и копить на особые награды."},
*/


export const faq=[
{title:"Что такое Магазин Наград?",
text:"Магазин Наград — это раздел портала, в котором вы можете обменять заработанные Протоны на реальные призы!"},
{title:"Что такое Протоны?",
text:"Протоны — это внутриигровая валюта в приложении Protone.app, которая начисляется пользователям за выполнение миссий."},
{title:"Как я могу получить Протоны?",
text:"<img src='./i/faq_how.jpg' alt='' /> Протоны вы можете заработать, проходя импакт-миссии в приложении Protone App. Импакт-миссии можно узнать по характерному уголку карточки миссии. В каждой карточке миссии указаны награды за прохождение."},
{title:"Как совершить обмен Протонов на призы из магазина?",
text:"Чтобы обменять Протоны, выберите приз из раздела “Награды”, нажмите кнопку “Обменять” и заполните все обязательные поля. Далее выберите  “Отправить”— после этого мы получим Вашу заявку на обмен, и как только обработаем ее, сразу напишем Вам в телеграмм для уточнения деталей и завершения обмена!<br><br>Обратите внимание, что без подтверждения Вами заявки в телеграм, обмен не может быть завершен. "},
{title:"В заявке на обмен требуется Импакт-ID. Где его найти?",
text:"<img src='./i/faq_impact.png' alt='' />Найти Импакт-ID можно в приложении в разделе «Настройки», далее необходимо перейти во вкладку «Профиль». Ваш импакт-ID находится под никнеймом и представляет собой сочетание букв и/или цифр."},
{title:"Есть ли какие-либо ограничения по обмену Протонов?",
text:"Обмен возможен только с одного и того же Импакт-ID для всех операций. Для обмена Протонов на призы мы также учитываем количество пройденных Вами импакт-миссий — их должно быть не менее 5."},
{title:"Могу ли я оформить подписку Telegram-premium на аккаунт, на котором премиум подписка еще активна? ",
text:"Активировать подписку на аккаунте, на котором уже есть действующая подписка Telegram-premium, к сожалению, технически невозможно. В таком случае можете обменять Протоны, когда подписка закончится, или выбрать другую награду из нашего магазина."},
{title:"Я могу подарить подписку Telegram-premium другу?",
text:"Активировать подписку можно только на Ваш аккаунт — дарить Telegram-premium другим пользователям нельзя."},
{title:"Платная ли доставка физических призов? ",
text:"Доставка осуществляется почтой России и стоит 590 Протонов. Для жителей Москвы предусмотрен самовывоз из нашего офиса (станция метро Тульская, БЦ Workii) — в таком случае доставка бесплатна. "},
{title:"Для доставки материальных призов нужны паспортные данные и ИНН. Зачем вам эта информация?",
text:"Паспортные данные нам необходимы исключительно для отчетности перед Федеральной Налоговой службой - иначе мы не сможем оформить передачу приза через бухгалтерию. Мы большая компания, и данные хранятся и обрабатываются соответствующим образом. Кроме того, спешим Вас успокоить: на данный момент на территории РФ невозможно совершить какие-либо подложные действия с Вашими документами без личного присутствия и согласия гражданина. Если вы не готовы поделиться указанными данными, Вы можете выбрать другой приз. Паспортные данные и ИНН мы запрашиваем только при выборе материального приза."},
{title:"Я отправил заявку, но со мной никто не связался. Почему?",
text:"Мы работаем по будням с 9:00 до 18:00 по московскому времени. По заявкам, отправленным после 18:00, мы напишем Вам утром в Telegram для уточнения деталей и завершения обмена!<br><br>Если же с Вами так и не связались, убедитесь, пожалуйста, в правильности написания вашего ника (нужен именно ник, как его найти описали <div class='faq_link' data-faq='11'>здесь</div>) и проверьте настройки приватности. Если Вы запретили писать невзаимным контактам или пользователям без премиум подписки, связаться с Вами у нас не получится. Обратите внимание, что без подтверждения Вами заявки в телеграм, обмен не может быть завершен. "},
{title:"Где найти ник в телеграм и что делать, если его нет?",
text:"Для пользователей iPhone: Выберите раздел “Настройки” в правом нижнем углу => мой профиль => скопируйте ник из графы “имя пользователя” <br><br>Для пользователей Android: Нажмите на три черточки в правом верхнем углу => выберите изображение своего профиля => скопируйте ник из графы “имя пользователя” <br><br>Если ника нет, напишите, пожалуйста, самостоятельно в бот магазина <a href='https://t.me/Protone_Store_Bot' target='_blank'>@protone_store_bot</a>, либо установите ник <div class='faq_link' data-faq='12'>по инструкции</div>, если захотите, конечно :)"},
{title:"Инструкция: как установить ник в Телеграм?",
text:"Для iPhone: <br>1. Выберите раздел “Настройки” в правом нижнем углу <br>2. В правом верхнем углу нажмите “Изм.”<br>3. В графу “Имя пользователя” введите желаемый ник<br>4. Нажмите “Готово”<br> <br><br>Для Android:<br><br>1. Коснитесь значка меню (три черточки в левом верхнем углу)<br>2. Перейдите в “Настройки”<br>3. Выберите ваш профиль<br>4. Нажмите на “Имя пользователя”<br>5. Введите желаемый ник и сохраните изменения "},
{title:"Платное ли приложение Protone.app?",
text:"Приложение Protone App абсолютно бесплатно для наших пользователей!"},
]